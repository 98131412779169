import React from 'react';
import Media from 'react-bootstrap/Media';
import Image from 'react-bootstrap/Image';

import './index.scss';

interface Props {
  image: string;
  title: string;
  description: string;
}

export function ImageListItem({ image, title, description }: Props) {
  return (
    <Media className="image-list-item">
      <Image className="img" width={48} height={48} rounded src={image} roundedCircle />
      <Media.Body>
        <h6 className="title">{title}</h6>
        <p className="text-description text">{description}</p>
      </Media.Body>
    </Media>
  );
}
