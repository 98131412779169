import React from 'react';
import { Link } from 'react-navi';
import { FiChevronLeft } from 'react-icons/fi';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

import './index.scss';

interface Props {
  href: string;
}

export function Pagination({ href }: Props) {
  return (
    <Row className="goa-pagination bg-grey">
      <Container>
        <Link className="goa-pagination-link" href={href}>
          <FiChevronLeft /> Go back
        </Link>
      </Container>
    </Row>
  );
}
