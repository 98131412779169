import React from 'react';
import { Link } from 'react-navi';
import classNames from 'classnames';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';

import './index.scss';

interface Props {
  className?: string;
  href: string;
  imgSrc: string;
  title: string;
  subtitle: string;
  text?: string;
}

export function ImageCard({ className, href, imgSrc, title, subtitle, text }: Props) {
  return (
    <Col className={classNames('image-card', className)} sm="6" md="4" lg="3">
      <Link href={href} className="card-link">
        <Card>
          <div>
            <Card.Img className="card-img" variant="top" src={imgSrc} />
          </div>
          <Card.Body>
            <Card.Title className="card-title">{title}</Card.Title>
            <Card.Subtitle className="card-text">{subtitle}</Card.Subtitle>
            <Card.Text className="card-text">{text}</Card.Text>
          </Card.Body>
        </Card>
      </Link>
    </Col>
  );
}
