import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

import './index.scss';

export function Footer() {
  return (
    <footer>
      <Container className="goa-footer" fluid>
        <Row>
          <a
            className="goa-footer-link center"
            href="https://gameofapps.org/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Back to Game of Apps Official Site
          </a>
        </Row>
      </Container>
    </footer>
  );
}
