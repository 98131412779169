import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Spinner from 'react-bootstrap/Spinner';

export function Loader() {
  return (
    <Container className="loader">
      <Row>
        <Spinner className="spinner" animation="grow" variant="warning" />
      </Row>
    </Container>
  );
}
