export enum Path {
  Root = '/',
  Regional1 = '/regionals1',
  Regional2 = '/regionals2',
  Provincial = '/provincials',
  Event = '/events',
  Team = '/teams',
  Guest = '/guests',
  Mentor = '/mentors',
  About = '/about',
  Vote = '/vote',
  AlreadyVoted = '/already-voted',
  Limited = '/too-many-requests',
  Success = '/success',
  Error = '/error',
  Select = '/select',
}

export const titleMap: Record<Path, string> = {
  [Path.Root]: 'championships',
  [Path.Regional1]: 'regionals1',
  [Path.Regional2]: 'regionals2',
  [Path.Provincial]: 'provincials',
  [Path.Event]: 'events',
  [Path.Team]: 'teams',
  [Path.Guest]: 'guests',
  [Path.Mentor]: 'mentors',
  [Path.About]: 'about',
  [Path.Vote]: 'vote',
  [Path.AlreadyVoted]: 'alreadyVoted',
  [Path.Limited]: 'tooManyRequests',
  [Path.Success]: 'success',
  [Path.Error]: 'error',
  [Path.Select]: 'select',
};

export const regionMap: Record<string, Path> = {
  'Regionals 1': Path.Regional1,
  'Regionals 2': Path.Regional2,
  Provincials: Path.Provincial,
};
