import React, { ReactNode } from 'react';
import classNames from 'classnames';

interface Props {
  className?: string;
  children: ReactNode;
}

export function Section({ className, children }: Props) {
  return <section className={classNames('section', className)}>{children}</section>;
}
