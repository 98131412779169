import React from 'react';
import { Link } from 'react-navi';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

import { Path } from '../../constants';
import { Section } from '.';

export function NotFound() {
  return (
    <Section className="mentor-detail-section bg-grey">
      <Container className="goa-page-container">
        <Container className="goa-block">
          <Row>
            <Col className="center">
              <Link href={Path.Root}>
                <Button variant="primary">Back to Championships</Button>
              </Link>
            </Col>
          </Row>
        </Container>
      </Container>
    </Section>
  );
}
